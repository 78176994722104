import { Injectable, Host, Optional } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public iziToast: Ng2IzitoastService,
  ) { }

  success(title = '¡Datos guardados correctamente!', msg = '') {
    this.iziToast.success({
      title: title,
      message: msg,
      position: 'topRight',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOutRight'
    });
  }

  info(title = 'Este es un mensaje informativo', msg = '') {
    this.iziToast.info({
      title: title,
      message: msg,
      position: 'topRight',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOutRight'
    });
  }

  danger(title = 'ERROR', msg = '') {
    this.iziToast.error({
      title: title,
      message: msg,
      position: 'topRight',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOutRight'
    });
  }
}
