import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
  styleUrls: ['./show-video.component.scss']
})
export class ShowVideoComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() fileUrl: string;

  constructor() { }

  ngOnInit() {
  }

}
