import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormGroupFilters } from '../../table-filters/model/table-filter.model';

@Component({
  selector: 'app-select-search-multiple',
  templateUrl: './select-search-multiple.component.html',
  styleUrls: ['./select-search-multiple.component.scss']
})
export class SelectSearchMultipleComponent implements OnInit, OnChanges {
  @Input() filter: FormGroupFilters;
  @Input() form: FormGroup;
  list: any[];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.filter.type === 'select-search-multiple') {
      this.generateList();
    }
  }

  generateList() {
    this.filter.searchOptions.forEach(element => {
      const nameComplete = element.name;
      element.nameComplete = nameComplete;
    });
    this.list = this.filter.searchOptions;
  };

}
