import { Routes } from '@angular/router';
import { UnderConstructionComponent } from '../components/under-construction/under-construction.component';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'business',
    loadChildren: './business/business.module#BusinessModule'
  },
  {
    path: 'category',
    loadChildren: './category/category.module#CategoryModule'
  },
  {
    path: 'interview',
    loadChildren: './interview/interview.module#InterviewModule'
  },
  {
    path: 'lesson',
    loadChildren: './lesson/lesson.module#LessonModule'
  },
  {
    path: 'module',
    loadChildren: './module/module.module#ModuleModule'
  },
  {
    path: 'professional',
    loadChildren: './professional/professional.module#ProfessionalModule'
  },
  {
    path: 'slide',
    loadChildren: './slide/slide.module#SlideModule'
  },
  {
    path: 'story',
    loadChildren: './story/story.module#StoryModule'
  },
  {
    path: 'quiz',
    loadChildren: './quiz/quiz.module#QuizModule'
  },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'workshop',
    loadChildren: './workshop/workshop.module#WorkshopModule'
  },
  {
    path: 'purchase',
    loadChildren: './purchase/purchase.module#PurchaseModule'
  },
  {
    path: 'maintenance',
    component: UnderConstructionComponent
  },
  {
    path: 'coupon',
    loadChildren: './coupon/coupon.module#CouponModule'
  },
];
