import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [],
  providers: [Ng2IzitoastService]
})
export class CoreModule { }
