import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/full-layout', title: 'Panel principal', icon: 'icon-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Catálogos', icon: 'ft-align-left', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin', title: 'Administradores', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {
        path: '/category', title: 'Categorías', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/lesson', title: 'Clases', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/coupon', title: 'Cupones', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/maintenance', title: 'Encuestas', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/interview', title: 'Entrevistas', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/story', title: 'Historias de la tribu', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/module', title: 'Módulos', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/business', title: 'Negocios', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/professional', title: 'Profesionales', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/maintenance', title: 'Publicidades', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/purchase', title: 'Compras', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/slide', title: 'Slides', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/workshop', title: 'Talleres', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/user', title: 'Usuarios', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
    ]
  }
];
