import { finalize, tap } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable, ViewContainerRef, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'app/core/services/toast/toast.service';
import { AuthService } from 'app/auth/services/auth.service';
import { LoaderService } from 'app/core/services/loader/loader.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        public toastr: ToastService,
        private router: Router,
        private loaderService: LoaderService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let ok: string;
        const token = this.authService.getToken();

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Api-Key': token ? token : ''
        });

        const foreignUrl = req.url.indexOf('amazonaws') > -1;
        if (foreignUrl) {
            headers = new HttpHeaders({
                'Accept': 'text/xml'
            })
        }

        const clonedReq = req.clone({ headers: headers });

        return next.handle(clonedReq)
            .pipe(
                tap(
                    event => { ok = event instanceof HttpResponse ? 'succeeded' : '' },
                    error => {
                        ok = error, this.onSubscribeError(ok)
                    }
                    //  Log when response observable either completes or errors
                ),
                finalize(() => {
                    this.loaderService.hide();
                })
            );
    }

    private onSubscribeError(error: any) {
        const message = error['error'].message;

        // Borro el token y lo mando al login
        if (error.status === 401 && message === 'InvalidToken') {
            this.authService.logout()
            this.router.navigate(['/auth']);
            return false;
        }

        this.toastr.danger('Error!', message);
    }


}
