import { Component, OnInit } from '@angular/core';
import { enterAnimation } from '../../animations/animations';
import { LoaderService } from 'app/core/services/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    enterAnimation
  ]
})
export class LoaderComponent implements OnInit {
  show: boolean;

  constructor(private loaderService: LoaderService) {

  }

  ngOnInit() {
    this.loaderService.loaderState.subscribe(
      isShowing => {
        this.show = isShowing;
      }
    )
  }

}
