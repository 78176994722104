export class FormGroupFilters {
  //  Mismo nombre que tienen el filtro del servicio
  name: string;
  //  Type element to show
  type: 'input' | 'input-date' | 'select' | 'select-search-multiple' | 'select-search-simple';
  //  Label to show element view
  labelName: string;
  //  Optional. Send if the filter is type select with the options (KEY - VALUE)
  options?: any[];
  //  Optional. Label to show in placeholder
  placeholder?: string;
  //  Optional. Send if the filter is type select-search-multiple or select-search-simple. Is necesary to use for label
  labelBind?: string;
  // Optional. Send if the filter is type select-search-multiple or select-search-simple. Is necesary to use for label
  valueBind?: string;
  //  Optional. Send if the filter is type select-search-multiple or select-search-simple. Not format, the format is in the component
  searchOptions?: any[];
  //
  defaultValue: any;

  constructor(fgf) {
    this.name = fgf.name;
    this.type = fgf.type;
    this.labelName = fgf.labelName;
    if (fgf.options) { this.options = fgf.options }
    if (fgf.placeholder) { this.placeholder = fgf.placeholder }
    if (fgf.labelBind) { this.labelBind = fgf.labelBind }
    if (fgf.searchOptions) { this.searchOptions = fgf.searchOptions }
    if (fgf.defaultValue) { this.defaultValue = fgf.labelBind }
  }
}

