import { trigger, state, style, animate, transition, keyframes, query, stagger } from '@angular/animations';

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('10ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('500ms', style({ opacity: 0 }))
    ])
  ]
);

export const fadeInFadeOutAnimation = trigger(
  'fadeInFadeOutAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('600ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('600ms', style({ opacity: 0 }))
    ])
  ]
);

