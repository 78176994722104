import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { TableLayoutComponent } from './components/table-layout/table-layout.component';
import { EditButtonTableComponent } from './components/table-action-buttons/edit-button/edit-button.component';
import { DeleteButtonTableComponent } from './components/table-action-buttons/delete-button/delete-button.component';
import { ViewButtonTableComponent } from './components/table-action-buttons/view-button/view-button.component';
import { AlertButtonComponent } from './components/table-action-buttons/alert-button/alert-button.component';
import { TableFiltersComponent } from './components/table-filters/table-filters.component';
import { InputDateComponent } from './components/table-filters-item/input-date/input-date.component';
import { InputComponent } from './components/table-filters-item/input/input.component';
import { SelectComponent } from './components/table-filters-item/select/select.component';
import { SelectSearchMultipleComponent } from './components/table-filters-item/select-search-multiple/select-search-multiple.component';
import { SelectSearchSimpleComponent } from './components/table-filters-item/select-search-simple/select-search-simple.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileBtnComponent } from './components/file-btn/file-btn.component';
import { CardImgComponent } from './components/card-img/card-img.component';
import { TruncateCustomPipe } from './pipes/truncate-custom.pipe';
import { HttpModule } from '@angular/http';
import { ShowFileComponent } from './components/show-file/show-file.component';
import { ShowVideoComponent } from './components/show-video/show-video.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';

const components = [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    TableLayoutComponent,
    TableFiltersComponent,
    LoaderComponent,
    FileBtnComponent,
    SelectSearchMultipleComponent,
    CardImgComponent,
    PaginationComponent,
    ShowFileComponent,
    ShowVideoComponent,
    UnderConstructionComponent,
    InputDateTimeComponent,
    UploadFilesComponent,
]
@NgModule({
    exports: [
        CommonModule,
        ToggleFullscreenDirective,
        NgbModule,
        TruncateCustomPipe,
        ...components
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        HttpModule
    ],
    declarations: [
        ...components,
        ToggleFullscreenDirective,
        InputDateComponent,
        InputComponent,
        SelectComponent,
        SelectSearchSimpleComponent,
        EditButtonTableComponent,
        DeleteButtonTableComponent,
        ViewButtonTableComponent,
        AlertButtonComponent,
        TruncateCustomPipe
    ],
    entryComponents: [UploadFilesComponent]
})
export class SharedModule { }
