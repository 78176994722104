import { Component } from '@angular/core';
import swal from 'sweetalert2';
import { AuthService } from 'app/auth/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    logout() {
        swal({
            title: '¿Cerrar sesión?',
            type: 'question',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then(value => {
            if (value.value) {
                this.authService.logout();
                this.router.navigate(['/auth'])
            }
        })
    }
}
