import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
    private loaderService: LoaderService) { }

  get(entity: string = '', params = undefined, showLoader = true) {
    if (showLoader) { this.loaderService.show(); }
    let url = entity;
    if (params) {
      url += '?';
      for (const key in params) { url += key + '=' + params[key] + '&'; }
    }
    return this.http.get(environment.apiUrl + url);
  }

  getOne(entity: string = '', id = undefined, showLoader = true) {
    if (showLoader) { this.loaderService.show(); }
    const url = entity + '/' + id;

    return this.http.get(environment.apiUrl + url);
  }

  post(url: string = '', params, showLoader = true) {
    if (showLoader) { this.loaderService.show(); }
    return this.http.post(environment.apiUrl + url, params);
  }

  put(url: string = '', params, showLoader = true) {
    if (showLoader) { this.loaderService.show(); }
    return this.http.put(environment.apiUrl + url, params);
  }

  delete(url: string = '', showLoader = true) {
    if (showLoader) { this.loaderService.show(); }
    return this.http.delete(environment.apiUrl + url);
  }

  postFile(url: string = '', formData, showLoader = true) {
    if (showLoader) { this.loaderService.show(); }
    return this.http.post(url, formData, { responseType: 'text' });
  }

  getExternal(url: string = '', showLoader = false) {
    if (showLoader) { this.loaderService.show(); }
    return this.http.get(url);
  }
}
