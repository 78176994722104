import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnSetting } from './model/table-layout.model';

@Component({
  selector: 'app-table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.scss']
})
export class TableLayoutComponent implements OnInit, OnChanges {
  @Input() records: any[];
  @Input() columnSettings: ColumnSetting[];
  @Input() tableActions;
  @Input() routeTitle: string;
  @Input() parent: string;
  @Input() buttonText: string;
  @Input() buttonIcon: string;
  @Input() showButtonAdd = true;
  @Input() perPage: number;
  @Input() totalRecords: number;
  @Input() pageNumber: number;
  @Input() hasText: boolean;
  @Input() tableText: string;

  @Output() deleteEntity = new EventEmitter();
  @Output() changePage = new EventEmitter();
  @Output() emitAlert = new EventEmitter();

  columnMaps: ColumnSetting[]
  keys: string[];
  editAction = true;
  deleteAction = true;
  totalColumns: number;
  viewAction = true;
  alertAction = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }


  ngOnInit() {
    this.routeTitle = this.route.snapshot.data['title'];
    this.parent = this.route.snapshot.data['parent'];
    this.totalColumns = this.columnSettings.length;
    if (this.tableActions) {
      this.totalColumns++;
    }
  }

  ngOnChanges() {
    if (this.columnSettings) { //  when settings provided
      this.columnMaps = this.columnSettings;
    } else { //  no settings, create column maps with defaults
      this.columnMaps = Object.keys(this.records[0])
        .map(key => {
          return {
            primaryKey: key,
            header: key.slice(0, 1).toUpperCase() +
              key.replace(/_/g, ' ').slice(1)
          }
        });
    }
    this.validateActions();
  }

  validateActions() {
    if (this.tableActions) {
      this.editAction = this.tableActions.some(elem => elem === 'edit');
      this.deleteAction = this.tableActions.some(elem => elem === 'delete');
      this.viewAction = this.tableActions.some(elem => elem === 'view');
      this.alertAction = this.tableActions.some(elem => elem === 'alert');
    }
  }

  addRoute() {
    this.router.navigate([this.parent + '/add']);
  }


  goToEdit(id) {
    this.router.navigate([this.parent + '/edit/', id]);
  }

  alert(id) {
    this.emitAlert.emit(id);
  }


  delete(id) {
    this.deleteEntity.emit(id);
  }

  view(id) {
    this.router.navigate([this.parent + '/detail/', id]);
  }

  getColumnInfo(record, primaryKey) {
    let word = ''
    let arrayPrimaryKeys = primaryKey.split(' ').join('');
    arrayPrimaryKeys = arrayPrimaryKeys.split(',');
    arrayPrimaryKeys.forEach(element => {
      const response = this.getData(record, element)
      if (response) {
        word = `${word}` + ` ` + `${response}`;
      }
    });
    return word;
  }

  getData(record, data) {
    return data.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, record || self)
  }

  onChangePage(event) {
    this.changePage.emit(event);
  }
}
