import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-delete-action-button',
    templateUrl: './delete-button.component.html',
    styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonTableComponent implements OnInit {

    @Input() idEntity: string;
    @Output() deleteEvent = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    deleteAction() {
        this.deleteEvent.emit(this.idEntity);
    }

}
