import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-view-action-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.scss']
})
export class ViewButtonTableComponent implements OnInit {
  @Input()
  idEntity: string;
  @Output()
  viewEvent = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  viewRoute() {
    this.viewEvent.emit(this.idEntity);
  }
}
