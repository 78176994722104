import { Professional } from 'app/model/professional';

export class CardImg {
  id: number;
  imageUrl = 'assets/img/gallery/1.jpg';
  title: string;
  description: string;
  professional: Professional;
  rating?: string;

  constructor(info) {
    this.id = info.id;
    if (info.imageUrl) {
      this.imageUrl = info.imageUrl;
    }
    this.title = info.title;
    this.description = info.description;
    if (info.professional) { this.professional = info.professional; }
    if (info.rating) { this.rating = info.rating; }
  }
}
