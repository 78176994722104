import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-edit-action-button',
    templateUrl: './edit-button.component.html',
    styleUrls: ['./edit-button.component.scss']
})
export class EditButtonTableComponent implements OnInit {

    @Input() idEntity: string;
    @Output() editEvent = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    editRoute() {
        this.editEvent.emit(this.idEntity);
    }


}
