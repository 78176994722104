import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/core/services/api/api.service';
import { Admin } from 'app/model/admin';
import { Observable } from 'rxjs/internal/Observable';
import { EndpointsConstants } from 'app/shared/constants/endpoint.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url: string;

  constructor(private router: Router,
    private apiService: ApiService) {
  }

  login(email: string, password: string): Observable<boolean> {
    const url = EndpointsConstants.admin.login;

    return new Observable<boolean>(observer => {
      this.apiService.post(url, { email, password }).subscribe(
        response => {
          const currentUser = response['data'].admin;
          const token = response['data'].token;
          localStorage.setItem('CET-currentUser', JSON.stringify(currentUser));
          localStorage.setItem('CET-token', JSON.stringify(token));
          observer.next(true);
        },
        error => {
          observer.next(false);
        }
      );
    });
  }

  logout(): void {
    localStorage.removeItem('CET-currentUser');
    localStorage.removeItem('CET-token');
  }

  isAuthenticated(state): boolean {
    const currentUser = JSON.parse(localStorage.getItem('CET-currentUser'));
    if (!currentUser && state.url !== '/auth') {
      this.router.navigate(['/auth']);
      return false;
    }
    return true;
  }

  getToken(): string {
    return JSON.parse(localStorage.getItem('CET-token'));
  }

  getUser(): Admin {
    return JSON.parse(localStorage.getItem('CET-currentUser'));
  }
}
