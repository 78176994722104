import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroupFilters } from '../../table-filters/model/table-filter.model';
import { FormGroup } from '@angular/forms';
import { toModelDate, isNullOrEmptyOrUndefined } from '../../../../helper/utils';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent implements OnInit {
  @Input() filter: FormGroupFilters;
  @Input() form: FormGroup;
  d2: any;

  constructor() { }

  ngOnInit() {

  }

}
