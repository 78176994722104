import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnChanges {
  filesToShow: any = [];

  @Input() name: any;
  @Input() currentfiles: any = [];
  @Output() uploadFiles = new EventEmitter();
  @Output() deleteFiles = new EventEmitter();
  @Output() closeDialog = new EventEmitter();

  constructor(
    private toastService: ToastService
  ) {}

  ngOnChanges() {
    this.filesToShow = this.currentfiles;
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if (element.type !== 'image/png' && element.type !== 'image/jpeg') {
        this.toastService.info(element.name, 'no es una Imagen');
      } else {
        if ((this.filesToShow === undefined || this.filesToShow.find(filename => filename === element.name)) === undefined) {
          this.toastService.info(element.name, 'Cargando imagen');
          this.uploadFiles.emit({ file: element, name: this.name });
        }
      }
    }
  }

  deleteAttachment(index) {
    this.filesToShow.splice(index, 1)
    this.deleteFiles.emit({ index: index, name: this.name });
  }

  onClose() {
    this.closeDialog.emit();
  }
}
