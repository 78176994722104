import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CardImg } from './model/card-img';


@Component({
  selector: 'app-card-img',
  templateUrl: './card-img.component.html',
  styleUrls: ['./card-img.component.scss']
})
export class CardImgComponent implements OnInit {

  @Input() data: any;
  @Input() height = '380px';
  @Output() deletePlease = new EventEmitter();
  info: CardImg;

  constructor() { }

  ngOnInit() {
    this.info = new CardImg(this.data);
  }

  trashClicked() {
    this.deletePlease.emit(this.info.id);
  }
}
