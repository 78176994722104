import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert-action-button',
  templateUrl: './alert-button.component.html',
  styleUrls: ['./alert-button.component.scss']
})
export class AlertButtonComponent implements OnInit {
  @Input() idEntity: string;
  @Output() alertEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  alertRoute() {
    this.alertEvent.emit(this.idEntity);
  }
}
