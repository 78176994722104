import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormGroupFilters } from '../../table-filters/model/table-filter.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-search-simple',
  templateUrl: './select-search-simple.component.html',
  styleUrls: ['./select-search-simple.component.scss']
})
export class SelectSearchSimpleComponent implements OnInit, OnChanges {
  @Input() filter: FormGroupFilters;
  @Input() form: FormGroup;
  @Output() changeItem = new EventEmitter();

  list: any;
  constructor() { }

  ngOnInit() {
    this.generateList();
  }

  ngOnChanges() {
    if (this.filter.type === 'select-search-simple') {
      this.generateList();
    }
  }

  changeItems(id) {
    this.changeItem.emit({
      name: this.filter.name,
      id: id.id
    });
  }

  generateList() {
    this.list = this.filter.searchOptions;
  }
}
