import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroupFilters } from '../../table-filters/model/table-filter.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() filter: FormGroupFilters;
  @Input() form: FormGroup;
  @Output()
  changeItem = new EventEmitter();

  constructor() { }

  ngOnInit() { }
  change(event) {
    this.changeItem.emit({
      name: this.filter.name,
      value: event.target['value']
    });
  }
}
