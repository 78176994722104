import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function isNullOrEmptyOrUndefined(field) {

  let valid = false;
  if (field === '' || field === null || field === undefined) {
    valid = true;
  }

  return valid;
}

export function isNull(field) {

  let valid = false;
  if (field === null) {
    valid = true;
  }

  return valid;
}

export function isEmpty(field) {

  let valid = false;
  if (field === '') {
    valid = true;
  }

  return valid;
}

export function isUndefined(field) {

  let valid = false;
  if (field === undefined) {
    valid = true;
  }

  return valid;
}

export function validateNumber(event: any) {
  const pattern = /[0-9]/;

  const inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode !== 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}

export function toModelDate(date: NgbDateStruct): Date {
  return date ? new Date(date.year + '-' + date.month + '-' + date.day) : null;
}

export enum FieldTypes {
  input = 'Texto',
  multipleInput = 'Texto Múltiple',
  select = 'Selección',
  textarea = 'Texto extenso',
  multipleSelect = 'Selección Múltiple',
  date = 'Fecha',
  number = 'Numérico',
  multipleNumber = 'Numérico Múltiple',
  file = 'Documento',
  multipleFile = 'Documento Múltiple',
}
export enum FieldTypesReverse {
  input = 'input',
  multipleInput = 'multipleInput',
  select = 'select',
  textarea = 'textarea',
  multipleSelect = 'multipleSelect',
  date = 'date',
  number = 'number',
  multipleNumber = 'multipleNumber',
  file = 'file',
  multipleFile = 'multipleFile'
}

export enum SelectTypes {
  custom = 'Custom',
  catalog = 'Catalogo',
}

export enum CatalogType {
  team = 'Grupos',
  client = 'Clientes',
  foreignCompany = 'Compañías Foráneas',
  agent = 'Agentes',
  shippingCompany = 'Navieras',
  airline = 'Aerolíneas',
  truck = 'Truckers',
  port = 'Puertos',
  vessel = 'Buques',
  provider = 'Vendedores',
  service = 'Servicios',
  container = 'Container',
}

export enum AlertTypes {
  yellow = 'Amarilla',
  red = 'Roja'
}

export const ContainerData: any = [
  // 0
  {
    label: 'Container',
    type: 'select',
    typeSelect: 'Catalogo',
    catalogSelected: 'Container',
    name: 'idContainerType',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: []
  },
  // 1
  {
    label: 'Sellos',
    type: 'multipleInput',
    isMultiple: 'true',
    typeSelect: '',
    catalogSelected: '',
    name: 'seals',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: []
  },
  // 2
  {
    label: 'Calidad',
    type: 'select',
    isMultiple: false,
    typeSelect: 'Custom',
    options: [
      {
        dependField: 'false',
        label: 'Food Grande',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Carga general',
        subsequentFields: []
      }
    ],
    catalogSelected: '',
    name: 'quality',
    answer: '',
    answers: '',
    subsequentFields: []
  },
  // 3
  {
    label: 'Tipo de Embalaje',
    type: 'multipleSelect',
    isMultipe: false,
    name: 'packageTypes',
    typeSelect: 'Custom',
    options: [
      {
        dependField: 'false',
        label: 'Package',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Drum',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Bag',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Pallet',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Box',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Jerrican',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Unit',
        subsequentFields: []
      }
    ],
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: []
  },
  // 4
  {
    label: 'Cantidad de Paquetes',
    type: 'number',
    typeSelect: '',
    catalogSelected: '',
    name: 'packageQty',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: []
  },
  // 5
  {
    label: 'Descripción de la mercancia',
    type: 'textarea',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'description'
  },
  // 6
  {
    label: 'Peso total',
    type: 'input',
    typeSelect: '',
    catalogSelected: '',
    name: 'totalWeight',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: []
  },
  // 7
  {
    label: 'Tipo de Carga',
    type: 'multipleSelect',
    isMultiple: false,
    typeSelect: 'Custom',
    name: 'loadTypes',
    options: [
      {
        dependField: 'false',
        label: 'Food Grande',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Carga general',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Carga Peligrosa',
        subsequentFields: [{ id: 8 }, { id: 9 }, { id: 10 }]
      },
      {
        dependField: 'false',
        label: 'Ro-Ro',
        subsequentFields: []
      },
      {
        dependField: 'false',
        label: 'Refrigerada',
        subsequentFields: [{ id: 11 }, { id: 12 }, { id: 13 }]
      }
    ],
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: []
  },
  // 8
  {
    label: 'Número de un',
    type: 'number',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'unNumber'
  },
  // 9
  {
    label: 'Clase',
    type: 'input',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'class'
  },
  // 10
  {
    label: 'MSDS',
    type: 'file',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'msds'
  },
  // 11
  {
    label: 'Temperatura',
    type: 'input',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'temperature'
  },
  // 12
  {
    label: 'Humedad',
    type: 'input',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'humidity'
  },
  // 13
  {
    label: 'Control de temperatura',
    type: 'input',
    typeSelect: '',
    catalogSelected: '',
    answer: '',
    answers: '',
    subsequentFields: [],
    options: [],
    name: 'temperatureControl'
  },
]
export function validateLetters() {
  return '[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúñÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÑ]+';
}

export function validateOnlyNumbers() {
  return /^([0-9])*$/;
}

export function validateEmail() {
  return /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,3})$/
}

export function repeatedEmail(value) {
  return { repeatedElement: value };
}

export function addOptionsToSelectInTableFilters(tableFilters, name, options) {
  const index = tableFilters.findIndex(filter => filter.name === name);
  tableFilters[index].searchOptions = options;
  return Object.assign([], tableFilters);
}

export function getFormArray(field: string, form: FormGroup | AbstractControl) {
  return (form.get(field) as FormArray);
}

export function getArrayByObjectKey(arr: any[], key) {
  const items = [];
  arr.forEach( item => {
      items.push(item[key]);
  });
  return items;
}

export function sortStates(states) {
  return states.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
}
