import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import swal from 'sweetalert2';

@Component({
  selector: 'app-file-btn',
  templateUrl: './file-btn.component.html',
  styleUrls: ['./file-btn.component.scss']
})
export class FileBtnComponent implements OnInit {
  fileName: string;
  @Input() showTooltip = true;
  @Input() buttonText: string;
  @Input() btnStyleClasses = 'btn-warning btn-sm text-bold-300 px-2 py-1';
  @Output() sendFile = new EventEmitter();
  @Input() name: any;
  @Input() accept = 'image/*';
  @Input() maxSize = 2000000;
  @Input() alertMsg = 'El tamaño máximo permitido es 2MB';

  constructor() {
  }

  ngOnInit() {
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    const element: HTMLElement = document.getElementById(this.name) as HTMLElement;
    element.click();
  }

  onFileChange(event) {
    let file = event.target.files[0];
    if (file.size > this.maxSize) {
      file = undefined;
      return swal({
        title: 'Error',
        text: this.alertMsg,
        type: 'error',
        confirmButtonColor: '#0CC27E',
        confirmButtonText: 'Cerrar',
        confirmButtonClass: 'btn btn-success btn-raised mr-5',
        buttonsStyling: false
      });
    }
    this.fileName = file.name;
    this.sendFile.emit({ file: file, name: this.name });
  }

}
