import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { FormGroupFilters } from './model/table-filter.model';
import { isNull, isUndefined } from 'util';
import { toModelDate, isEmpty } from '../../../helper/utils';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit, OnChanges {
  @Input() filters: FormGroupFilters[];
  @Output() submitFilters = new EventEmitter();
  @Output() resetFilter = new EventEmitter();
  @Output() changeItems = new EventEmitter();
  filtersForm: FormGroup = this.fb.group({});

  constructor(private fb: FormBuilder, private datePipe: DatePipe) { }

  ngOnInit() {
    this.filtersForm = this.fb.group({});
    this.toFormGroup(this.filters);
  }

  onSubmit() {
    const formModel = this.filtersForm.value;
    const response = {};

    //  format to send API filters

    this.filters.forEach(element => {
      let value = formModel[element.name];
      if (!(isNull(value) || isEmpty(value))) {
        if (element.type === 'input-date') {
          value = this.updateValue(value);
        }
        response[element.name] = value;
      }
    });

    this.submitFilters.emit(response);
  }

  ngOnChanges(change: SimpleChanges) {
    this.toFormGroup(change.filters.currentValue);
  }

  toFormGroup(filters: FormGroupFilters[]) {
    filters.forEach(element => {
      this.filtersForm.addControl(
        element.name.toString(),
        new FormControl(element.defaultValue)
      );
    });
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filters.forEach(element => {
      this.filtersForm.controls[element.name.toString()].setValue(
        element.defaultValue
      );
    });
    this.resetFilter.emit();
  }

  changeItem(event) {
    this.changeItems.emit(event);
  }

  updateValue(dateValue) {
    let dateToSave = null;
    const dateFormatted = toModelDate(dateValue);

    if (!(isUndefined(dateValue) && isNull(dateValue))) {
      dateToSave = this.transformDate(dateFormatted);
    }
    return dateToSave;
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
}
