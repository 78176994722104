import { Component, OnInit, Input } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-show-file',
  templateUrl: './show-file.component.html',
  styleUrls: ['./show-file.component.scss']
})
export class ShowFileComponent implements OnInit {
  @Input() fileUrl: string;

  constructor() { }

  ngOnInit() {
  }

  showPreview() {
    swal({
      title: 'Vista previa',
      confirmButtonText: 'Cerrar',
      html: '<img src="' + this.fileUrl + '" style="max-width:100%"/>'
    });
  }
}
