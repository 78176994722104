import { Component, OnInit } from '@angular/core';
import { CommonConstants } from 'app/shared/constants/common.constants';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {

  mainImg = CommonConstants.logos.underConstructionImg;

  constructor() { }

  ngOnInit() {
  }

}
