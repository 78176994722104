export const EndpointsConstants = {
  requestLimit: 20,
  admin: {
    login: '/auth/admin-login',
    all: '/admins',
    me: '/admins/me',
    single: '/admins/{id}'
  },
  businesses: {
    all: '/businesses',
    single: '/businesses/{id}'
  },
  categories: {
    all: '/categories',
    single: '/categories/{id}'
  },
  coupons: {
    all: '/coupons',
    single: '/coupons/{id}'
  },
  files: {
    uploadImage: '/files',
    uploadVideo: '/files/video',
    uploadPdf: '/files/pdf'
  },
  interviews: {
    all: '/interviews',
    single: '/interviews/{id}'
  },
  lessons: {
    all: '/lessons',
    single: '/lessons/{id}'
  },
  modules: {
    all: '/modules',
    single: '/modules/{id}'
  },
  professionals: {
    all: '/professionals',
    single: '/professionals/{id}'
  },
  purchases: {
    all: '/purchases'
  },
  slides: {
    all: '/slides',
    single: '/slides/{id}'
  },
  stories: {
    all: '/tribu-histories',
    single: '/tribu-histories/{id}'
  },
  users: {
    all: '/users',
    single: '/users/{id}'
  },
  workshops: {
    all: '/workshops',
    single: '/workshops/{id}'
  }
}
